import React, { useState, useEffect, useRef } from 'react';
import base64 from 'base-64';
import utf8 from 'utf8';
import { Button, Spin } from 'antd';
import TablePage from './Table';
import Add from './Add';
import Edit from './Edit';
import db from '../../utils/firestore';
import {
  collection,
  query,
  where,
  getDocs,
  // addDoc,
  doc,
  deleteDoc,
  setDoc,
  writeBatch,
  onSnapshot
} from 'firebase/firestore';
import { vendor as VOption } from '../../config/vendor';

export default function Index() {
  const { current: purchaseRef } = useRef(collection(db, 'purchase'));
  const { current: productRef } = useRef(collection(db, 'product'));
  const [showInfo, setShowInfo] = useState({ date: undefined, vendor: '' });
  const [data, setData] = useState([]);
  const [showType, setShowType] = useState('');
  const [editId, setEditId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showInfo.vendor) {
      setLoading(true);
      const q = query(
        purchaseRef,
        where('year', '==', showInfo.date.format('YYYY')),
        where('month', '==', showInfo.date.format('MM')),
        where('day', '==', showInfo.date.format('DD')),
        where('vendor', '==', showInfo.vendor)
      );
      return onSnapshot(q, querySnapshot => {
        // console.log('🚀 ~ file: index.jsx:56 ~ return ~ querySnapshot:', querySnapshot);
        setLoading(true);
        const now = [];
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach(doc => {
            now.push({ ...doc.data(), id: doc.id });
          });
        }
        // console.log('🚀 ~ file: index.jsx:54 ~ useEffect ~ now:', now);
        setData(now);
        setLoading(false);
      });
    }
  }, [showInfo, purchaseRef]);

  const create = async data => {
    setLoading(true);
    // console.log(JSON.stringify(data));
    const vendor = data.vendor || showInfo.vendor;
    const date = data.date || showInfo.date;
    // console.log(`vendor=>${vendor}, date=>${date.format('YYYY/MM/DD')},`)
    if (data.pids && data.pids.length > 0) {
      const q = query(productRef, where('vendor', '==', vendor));
      const querySnapshot = await getDocs(q);

      const prodDatas = {};
      querySnapshot.forEach(doc => {
        const now = { ...doc.data() };
        // prodDatas.push({ id: doc.id, ...doc.data() });
        prodDatas[now.pid] = now;
      });
      const batch = writeBatch(db);
      for (let i = 0; i < data.pids.length; i++) {
        const item = data.pids[i];
        let newItem = {
          time: +new Date() + i,
          vendor: vendor,
          year: date.format('YYYY'),
          month: date.format('MM'),
          day: date.format('DD'),
          date: parseInt(date.format('YYYYMMDD')),
          return: false,
          name: '',
          spec: '',
          unit: '',
          price: 0,
          ...item
        };
        newItem.pid = newItem.pid.toUpperCase();
        if (prodDatas[newItem.pid]) {
          newItem = { ...newItem, ...prodDatas[newItem.pid] };
        }
        const newDoc = doc(purchaseRef);
        batch.set(newDoc, newItem);
        // await addDoc(purchaseRef, newItem);
      }
      await batch.commit();
    }
    if (data.vendor) {
      setShowInfo({ date: data.date, vendor: data.vendor });
    }
    setLoading(false);
  };

  const edit = async data => {
    // console.log(JSON.stringify(data));
    const newData = { ...data };
    const docRef = doc(purchaseRef, newData.id);
    delete newData.id;
    await setDoc(docRef, newData, { merge: true });
    const bytes = utf8.encode(newData.vendor);
    const encoded = base64.encode(bytes);
    const pdRef = doc(productRef, `${encoded}${newData.pid}`);
    await setDoc(
      pdRef,
      {
        pid: newData.pid,
        name: newData.name,
        spec: newData.spec,
        unit: newData.unit,
        price: newData.price,
        vendor: newData.vendor
      },
      { merge: true }
    );
  };

  const del = async data => {
    console.log('🚀 ~ file: index.jsx:131 ~ del ~ data:', data);
    await deleteDoc(doc(purchaseRef, data.id));
  };

  return (
    <div className="flex">
      <div style={{ padding: 8 }}>
        <Button
          onClick={() => {
            setShowType('CREATE');
          }}
          style={{ marginRight: 4 }}
        >
          建立新單
        </Button>
        <Button
          onClick={() => {
            setShowType('ADD');
          }}
          style={{ marginRight: 4 }}
        >
          新增筆數
        </Button>
        <Button
          onClick={() => {
            setShowType('QUERY');
          }}
        >
          查詢單據
        </Button>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            height: 50,
            padding: 14,
            display: loading && data.length === 0 ? 'block' : 'none'
          }}
        >
          <Spin />
        </div>
        {/* {JSON.stringify(data)} */}
        {data.length > 0 && (
          <TablePage
            loading={loading}
            data={data.sort((a, b) => a.time - b.time)}
            onEdit={data => {
              setShowType('EDIT');
              setEditId(data.id);
            }}
            onDel={del}
          />
        )}
        <Add
          visible={showType === 'CREATE' || showType === 'ADD' || showType === 'QUERY'}
          type={showType}
          onClose={() => {
            setShowType('');
          }}
          onSave={create}
          VOption={VOption}
        />
        <Edit
          visible={showType === 'EDIT' && editId}
          onClose={() => {
            setShowType('');
          }}
          onSave={edit}
          data={data.find(i => i.id === editId)}
        />
      </div>
    </div>
  );
}
