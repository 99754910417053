import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import db from '../utils/firestore';
import { Card, Button, App } from 'antd';
import { AuthContext } from '../providers/auth';
import Icon from '@ant-design/icons';

const LoginSvg = () => (
  <svg viewBox="0 0 32 32" width="20px" height="20px">
    <g>
      <path d="M11.71,15.29l-4-4a1,1,0,0,0-1.09-.21A1,1,0,0,0,6,12v1H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H6v1a1,1,0,0,0,.62.92A.84.84,0,0,0,7,21a1,1,0,0,0,.71-.29l4-4A1,1,0,0,0,11.71,15.29Z" />
      <path d="M20,29.52a2,2,0,0,1-1.12-.34A2,2,0,0,1,18,27.52v-23a2,2,0,0,1,2.74-1.86l8,3.2A2,2,0,0,1,30,7.68V24.32a2,2,0,0,1-1.26,1.86l-8,3.2A1.91,1.91,0,0,1,20,29.52Zm0-25V27.53l8-3.21V7.68Z" />
      <path d="M22,19a1,1,0,0,1-1-1V14a1,1,0,0,1,2,0v4A1,1,0,0,1,22,19Z" />
      <path d="M19,5H15a2,2,0,0,0-2,2V26a2,2,0,0,0,2,2h4a1,1,0,0,0,1-1V6A1,1,0,0,0,19,5Z" />
      <path d="M20,24c6.83-.25,8-5,8-6.67l1,7a1,1,0,0,1-.63.93l-8.53,3.47-.23-3.5Z" />
    </g>
  </svg>
);

const LoginIcon = props => <Icon component={LoginSvg} {...props} />;

const Login = () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  const login = async () => {
    setLoading(true);
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user && setUser) {
        const docReference = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docReference);
        if (docSnap.exists()) {
          // console.log('🚀 ~ file: Login.jsx:53 ~ login ~ user:', user);
          setUser(user);
          setTimeout(() => navigate('/'), 0);
          message.info('登入成功');
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
    message.error('登入失敗');
    setLoading(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Card className="login" style={{ width: 236, height: 82 }} loading={loading}>
        <Button onClick={login}>
          <LoginIcon /> Sign In with Google
        </Button>
      </Card>
    </div>
  );
};

export default Login;
