import React, { useEffect } from 'react';
import { Modal, Form, Select, Input } from 'antd';
const { Option } = Select;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const [form] = Form.useForm();
  useEffect(() => {
    const data = { ...props.data };
    form.setFieldsValue(data);
  }, [props.visible, form, props.data, props.isDetail, props.VOption]);

  const handleOk = async e => {
    const a = await form.validateFields();
    props.onSave({ ...props.data, ...a, type: 'DATE', isDetail: props.isDetail });
    props.onClose();
  };

  const handleCancel = e => {
    props.onClose();
  };

  return (
    <Modal
      title="查詢"
      open={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔"
      cancelText="取消"
      maskClosable={false}
    >
      <Form className="edit" form={form} scrollToFirstError>
        <Form.Item name="keyword" label="品名">
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="vendor"
          label="廠商"
          rules={[{ required: true, message: 'Missing vendor' }]}
          initialValue={props.VOption}
        >
          <Select style={{ width: '100%' }} allowClear mode="multiple">
            {props.VOption.map(i => (
              <Option key={i} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
