import React, { useState } from 'react';
import './edit.css';
import dayjs from 'dayjs';
import { DatePicker, Modal, Form, Input, InputNumber, Select, Button, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined, FileTextTwoTone } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;

const TITLE = {
  CREATE: '建立單據',
  ADD: '新增筆數',
  QUERY: '查詢單據'
};

export default function Add(
  props = {
    VOption: [],
    visible: true,
    onClose: () => {},
    onSave: () => {},
    data: {}
  }
) {
  const [form] = Form.useForm();
  const [textAreaFlag, setTextAreaFlag] = useState(true);
  const [textArea, setTextArea] = useState('');

  const handleCancel = e => {
    setTextAreaFlag(true);
    setTextArea('');
    props.onClose();
    form.resetFields();
  };

  const handleOk = async e => {
    const a = await form.validateFields();
    if (textAreaFlag) {
      a.pids = [];
      const arr = textArea.split('\n');
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        const [pid, quantity] = element.split(',');
        if (pid.trim() !== '' && quantity.trim() !== '') {
          a.pids.push({ pid: pid.trim(), quantity: quantity.trim() });
        }
      }
    }
    // console.log(JSON.stringify(a, null, 2));
    props.onSave({ ...props.data, ...a });
    handleCancel();
  };

  return (
    <Modal
      title={
        <>
          <FileTextTwoTone
            style={{ marginRight: 6 }}
            onClick={() => setTextAreaFlag(!textAreaFlag)}
          />
          {TITLE[props.type]}
        </>
      }
      open={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔"
      cancelText="取消"
      maskClosable={false}
      getContainer={false}
    >
      <Form className="edit" form={form} scrollToFirstError>
        {props.type !== 'ADD' && (
          <Form.Item
            name="date"
            label="日期"
            rules={[{ required: true, message: 'Missing day' }]}
            initialValue={dayjs()}
          >
            <DatePicker format={'YYYY-MM-DD'} allowClear={false} style={{ width: '100%' }} />
          </Form.Item>
        )}
        {props.type !== 'ADD' && (
          <Form.Item
            name="vendor"
            label="廠商"
            rules={[{ required: true, message: 'Missing vendor' }]}
          >
            <Select style={{ width: '100%' }} allowClear>
              {props.VOption.map(i => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {props.type !== 'QUERY' &&
          (textAreaFlag ? (
            <TextArea
              rows={8}
              value={textArea}
              onChange={e => setTextArea(e.target.value)}
              placeholder={`PID,Quantity(number)\nR5001,10`}
            />
          ) : (
            <Form.List name="pids">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.quantity !== curValues.quantity ||
                          prevValues.pids !== curValues.pids
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="產品編號"
                            name={[field.name, 'pid']}
                            fieldKey={[field.fieldKey, 'pid']}
                            rules={[{ required: true, message: 'Missing pid' }]}
                          >
                            <Input />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="數量"
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                        rules={[{ required: true, message: 'Missing quantity' }]}
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      新增筆數
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          ))}
      </Form>
    </Modal>
  );
}
