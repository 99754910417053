import React from 'react';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import format_with_substring from '../../utils/number';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { data, onEdit } = props;
  if (!data || data.length === 0) {
    return null;
  }
  const columns = [
    {
      title: '廠商',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center',
      sorter: (a, b) => {
        return ('' + a.vendor).localeCompare(b.vendor);
      }
    },
    {
      title: '產品編號',
      dataIndex: 'pid',
      key: 'pid',
      align: 'center',
      sorter: (a, b) => {
        return ('' + a.pid).localeCompare(b.pid);
      },
      render: t => <span onClick={() => props.onClick(t)}>{t}</span>
    },
    {
      title: '單價',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      sorter: (a, b) => {
        return a.price - b.price;
      },
      render: t => format_with_substring(t.toFixed(2))
    },
    {
      title: '品名',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => {
        return ('' + a.name).localeCompare(b.name);
      }
    },
    {
      title: '規格',
      dataIndex: 'spec',
      key: 'spec',
      align: 'center'
    },
    {
      title: '數量',
      dataIndex: 'pieces',
      key: 'pieces',
      align: 'center',
      render: (pieces, { price }) =>
        `${price} / ${pieces ?? 1} = ${Math.ceil(price / (pieces ?? 1))}`
    },
    {
      title: '單位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center'
    },
    {
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: 30,
      render: (_, row) => (
        <>
          <EditOutlined onClick={() => onEdit(row)} style={{ marginRight: 4 }} />
        </>
      )
    }
  ];

  return (
    <Table
      loading={props.loading}
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="id"
      style={{ marginTop: 4 }}
    />
  );
}
