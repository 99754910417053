import React, { useContext } from 'react';
import { createHashRouter, RouterProvider, Navigate } from 'react-router-dom';
import { App as AntdApp } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  ExperimentOutlined,
  MacCommandOutlined
} from '@ant-design/icons';
import { AuthContext } from './auth';
import App from '../layouts/App';
import Keyin from '../pages/keyin';
import Board from '../pages/board';
import Prod from '../pages/prod';
import History from '../pages/history';
import Fix from '../pages/fix';
// import { HomePage } from '../components/home-page';
// import { AddPage } from '../components/add-page';
import LoginPage from '../pages/Login';
// import { ProtectedRoute } from '../components/protected-route';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  // console.log('🚀 ~ file: route.jsx:15 ~ PrivateRoute ~ user:', user);
  return user ? children : <Navigate to="/login" />;
};

export const route = [
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    children: [
      {
        path: '/',
        icon: UploadOutlined,
        title: '輸入資料',
        element: <Keyin />
      },
      {
        path: '/board',
        icon: UserOutlined,
        title: '總結查詢',
        element: <Board />
      },
      {
        path: '/product',
        icon: VideoCameraOutlined,
        title: '產品查詢',
        element: <Prod />
      },
      {
        path: '/history/',
        icon: ExperimentOutlined,
        title: '產品細節',
        element: <History />
      },
      {
        path: '/product/:pid',
        icon: ExperimentOutlined,
        title: '產品細節',
        element: <History />,
        hideMenu: true
      },
      {
        path: '/fix',
        icon: MacCommandOutlined,
        title: '產品修改',
        element: <Fix />
      }
    ]
  }
];

const router = createHashRouter(route);

export const AppRouterProvider = () => {
  return (
    <AntdApp>
      <RouterProvider router={router} />
    </AntdApp>
  );
};
