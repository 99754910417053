// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiKey: "AIzaSyCBDbJV354r6WAOLEschUhMzEZV_KMm8Kg",
  authDomain: "purchase-5e148.firebaseapp.com",
  projectId: "purchase-5e148",
  storageBucket: "purchase-5e148.appspot.com",
  messagingSenderId: "544748549391",
  appId: "1:544748549391:web:e9d1fe968b9ca8c3d2ba7a"
};
// export default {
//   apiKey: "AIzaSyDezHEFr88WFfu6oPNdqUgYqZ3tOQ_xUyk",
//   authDomain: "test-a0108.firebaseapp.com",
//   databaseURL: "https://test-a0108.firebaseio.com",
//   projectId: "test-a0108",
//   storageBucket: "test-a0108.appspot.com",
//   messagingSenderId: "822526928703",
//   appId: "1:822526928703:web:3b862f040f5a0b4a9dd58a",
// };
