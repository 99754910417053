import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import TablePage from './Table';
import Query from './Query';
import Edit from './Edit';
import db from '../../utils/firestore';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { vendor as VOption } from '../../config/vendor';

export default function Index(prop) {
  const { current: productRef } = useRef(collection(db, 'product'));
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showType, setShowType] = useState('');
  const [editId, setEditId] = useState('');
  const [showInfo, setShowInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const querySnapshot = await getDocs(productRef);
      // console.log('🚀 ~ file: index.jsx:22 ~ querySnapshot:', querySnapshot);
      const saveData = [];
      querySnapshot.forEach(doc => {
        saveData.push({ id: doc.id, ...doc.data() });
      });
      setData(
        saveData
          .sort((a, b) => a.pid - b.pid)
          .sort((a, b) => a.vendor.localeCompare(b.vendor, 'zh-hant'))
      );
      setLoading(false);
    })();
  }, [productRef]);

  const query = data => {
    setShowInfo(data);
  };

  let useData = data;
  let queryString = '';
  if (showInfo.keyword) {
    queryString += `關鍵字: ${showInfo.keyword}`;
    useData = useData.filter(i => i.name.indexOf(showInfo.keyword) > -1);
  }

  if (showInfo.vendor) {
    useData = useData.filter(i => showInfo.vendor.indexOf(i.vendor) > -1);
  }

  const edit = async data => {
    const docRef = doc(productRef, data.id);
    await setDoc(
      docRef,
      {
        pid: data.pid,
        name: data.name,
        spec: data.spec,
        unit: data.unit,
        price: data.price,
        vendor: data.vendor,
        pieces: data.pieces
      },
      { merge: true }
    );
  };

  const clickDetail = pid => {
    navigate(`/product/${pid}`);
  };

  return (
    <div className="flex">
      <div style={{ padding: 8, width: '100%' }}>
        <Button
          onClick={() => {
            setShowType('QUERY');
          }}
          style={{ marginRight: 4 }}
        >
          查詢
        </Button>
        {queryString && (
          <p style={{ margin: 8, display: 'inline-block' }}>
            <span style={{ marginRight: 10 }}>{queryString}</span>
          </p>
        )}
        <div style={{ maxWidth: '100%', overflow: 'scroll' }}>
          <div style={{ minWidth: 900 }}>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: 50,
                padding: 14,
                display: loading && useData.length === 0 ? 'block' : 'none'
              }}
            >
              <Spin />
            </div>
            <TablePage
              onClick={clickDetail}
              onEdit={data => {
                setShowType('EDIT');
                setEditId(data.id);
              }}
              loading={loading}
              data={useData}
            />
          </div>
        </div>
        <Query
          visible={showType === 'QUERY'}
          onClose={() => {
            setShowType('');
          }}
          onSave={query}
          VOption={VOption}
          data={showInfo}
        />
        <Edit
          visible={showType === 'EDIT' && editId}
          onClose={() => {
            setShowType('');
          }}
          onSave={edit}
          data={data.find(i => i.id === editId)}
        />
      </div>
    </div>
  );
}
