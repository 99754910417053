import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Button, Spin } from 'antd';
import TablePage from './Table';
import TableTotalPage from './TableTotal';
import Query from './Query';
import QueryMonth from './QueryMonth';
import db from '../../utils/firestore';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { vendor as VOption } from '../../config/vendor';

export default function Index() {
  const { current: purchaseRef } = useRef(collection(db, 'purchase'));
  const [showInfo, setShowInfo] = useState({ isDetail: false, type: '' });
  const [data, setData] = useState({});
  const [showType, setShowType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showInfo.type) {
      // console.log('🚀 ~ file: index.jsx:21 ~ useEffect ~ showInfo:', showInfo);
      (async () => {
        setLoading(true);
        let start = dayjs();
        let end = dayjs();
        if (showInfo.type === 'DATE') {
          start = showInfo.date[0];
          end = showInfo.date[1];
        } else if (showInfo.type === 'MONTH') {
          start = dayjs(showInfo.month).subtract(1, 'month').set('date', 26);
          end = dayjs(showInfo.month).endOf('month');
        }
        parseInt(start.format('YYYYMMDD'));
        // console.log(
        //   "🚀 ~ file: index.jsx:36 ~ parseInt(start.format('YYYYMMDD')):",
        //   parseInt(start.format('YYYYMMDD'))
        // );
        const q = query(
          purchaseRef,
          where('date', '>=', parseInt(start.format('YYYYMMDD'))),
          where('date', '<=', parseInt(end.format('YYYYMMDD'))),
          where('vendor', 'in', showInfo.isDetail ? [showInfo.vendor] : showInfo.vendor)
        );
        const querySnapshot = await getDocs(q);
        // console.log(
        //   '🚀 ~ file: index.jsx:44 ~ querySnapshot.docs.length :',
        //   querySnapshot.docs.length
        // );
        if (querySnapshot.docs.length > 0) {
          const now = {};
          querySnapshot.forEach(doc => {
            const nowData = doc.data();
            if (!now[nowData.vendor]) {
              now[nowData.vendor] = [];
            }
            if (showInfo.type === 'MONTH') {
              const startTemp = parseInt(dayjs(showInfo.month).startOf('month').format('YYYYMMDD'));
              const endTemp = parseInt(dayjs(showInfo.month).set('date', 26).format('YYYYMMDD'));
              if (['祥雲', '揚明'].indexOf(nowData.vendor) > -1 && nowData.date >= endTemp) {
                return;
              }
              if (
                ['源吉', '伯明', '祐恆'].indexOf(nowData.vendor) > -1 &&
                nowData.date < startTemp
              ) {
                return;
              }
            }
            now[nowData.vendor].push({ id: doc.id, ...nowData });
          });
          setData(now);
          setLoading(false);
        }
      })();
    }
  }, [showInfo, purchaseRef]);

  const doQuery = data => {
    // console.log(data);
    setShowInfo(data);
  };

  const tables = Object.keys(data);

  let queryString = '';
  if (showInfo.type === 'MONTH') {
    queryString = `${showInfo.month.format('YYYY/MM')}`;
  } else if (showInfo.type === 'DATE') {
    queryString = `${showInfo.date[0].format('YYYY/MM/DD')} ~ ${showInfo.date[1].format(
      'YYYY/MM/DD'
    )}`;
  }

  const UseTable = showInfo.isDetail ? TablePage : TableTotalPage;

  return (
    <div className="flex">
      <div style={{ padding: 8, width: '100%' }}>
        <Button
          onClick={() => {
            setShowType('QUERYMONTH');
          }}
          style={{ marginRight: 6 }}
        >
          月份
        </Button>
        <Button
          onClick={() => {
            setShowType('QUERYMONTHDETAIL');
          }}
          style={{ marginRight: 16 }}
        >
          月份(細)
        </Button>
        <Button
          className="s-hide"
          onClick={() => {
            setShowType('QUERY');
          }}
          style={{ marginRight: 6 }}
        >
          區間
        </Button>
        <Button
          className="s-hide"
          onClick={() => {
            setShowType('QUERYDETAIL');
          }}
          style={{ marginRight: 6 }}
        >
          區間(細)
        </Button>
        {queryString && (
          <p style={{ margin: 8, display: 'inline-block' }}>
            <span style={{ marginRight: 10 }}>查詢區間：{queryString}</span>
          </p>
        )}
        {/* <p>{JSON.stringify(showInfo)}</p> */}
        {/* {tables.map(i => (<TablePage key={i} data={data[i].sort((a, b) => a.time - b.time).sort((a, b) => a.date - b.date)} />))} */}
        <div style={{ maxWidth: '100%', overflow: 'scroll' }}>
          <div style={{ minWidth: showInfo.isDetail ? 1000 : '100%' }}>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: 50,
                padding: 14,
                display: loading && tables.length === 0 ? 'block' : 'none'
              }}
            >
              <Spin />
            </div>
            {tables.map(i => (
              <UseTable
                loading={loading}
                key={i}
                data={data[i].sort((a, b) => a.time - b.time).sort((a, b) => a.date - b.date)}
              />
            ))}
          </div>
        </div>
        <Query
          visible={showType === 'QUERY' || showType === 'QUERYDETAIL'}
          onClose={() => {
            setShowType('');
          }}
          onSave={doQuery}
          VOption={VOption}
          data={showInfo}
          isDetail={showType === 'QUERYDETAIL'}
        />
        <QueryMonth
          visible={showType === 'QUERYMONTH' || showType === 'QUERYMONTHDETAIL'}
          onClose={() => {
            setShowType('');
          }}
          onSave={doQuery}
          VOption={VOption}
          data={showInfo}
          isDetail={showType === 'QUERYMONTHDETAIL'}
        />
      </div>
    </div>
  );
}
