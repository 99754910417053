import React, { useEffect } from 'react';
import './edit.css';
import { Modal, Form, Input, InputNumber } from 'antd';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { data } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      pieces: 1,
      ...data
    });
  }, [data, form]);
  const handleOk = async e => {
    const a = await form.validateFields();
    // console.log(JSON.stringify(a));
    props.onSave({ ...props.data, ...a });
    props.onClose();
  };

  const handleCancel = e => {
    props.onClose();
  };

  return (
    <Modal
      title="編輯"
      open={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔"
      cancelText="取消"
      maskClosable={false}
    >
      <Form className="edit" form={form} scrollToFirstError>
        {props.data && (
          <div>
            <Form.Item
              name="pid"
              label="產品編號"
              rules={[{ required: true, message: 'Missing pid' }]}
            >
              {props.data.pid}
              <Input style={{ width: '100%' }} type="hidden" />
            </Form.Item>
            <Form.Item
              name="name"
              label="品名"
              rules={[{ required: true, message: 'Missing name' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="spec" label="規格">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="unit" label="單位">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="price"
              label="單價"
              rules={[{ required: true, message: 'Missing price' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="pieces"
              label="數量"
              rules={[{ required: true, message: 'Missing price' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
}
