import React, { useState, useEffect } from 'react';
import './App.css';
import { Outlet, Link, useLocation } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { route } from '../providers/route';

const { Content, Footer, Sider } = Layout;

function App() {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <Layout>
      <Sider
        collapsed={windowSize.width > 990 ? false : open}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
          if (type === 'clickTrigger') {
            setOpen(collapsed);
          }
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname.match(/\/[-a-zA-Z0-9@:%._+~#=]{0,}/)[0]]}
          items={route[1].children
            .filter(i => !i.hideMenu)
            .map(({ path, icon, title }, index) => ({
              key: path,
              icon: icon ? React.createElement(icon) : null,
              // label: title
              label: (
                <Link to={path} onClick={() => setOpen(true)}>
                  {title}
                </Link>
              )
            }))}
        />
      </Sider>
      <Layout>
        <Content>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2023</Footer>
      </Layout>
    </Layout>
  );
}

export default App;
