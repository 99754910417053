import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../../utils/firestore';
import { Input, Row, Col, Button, Card, Space } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import TablePage from './Table';

export default function Index() {
  const { current: purchaseRef } = useRef(collection(db, 'purchase'));
  const { pid } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    navigate(`/product/${search}`);
  };

  useEffect(() => {
    if (pid) {
      setSearch(pid);
      (async () => {
        if (pid) {
          setLoading(true);
          const q = query(purchaseRef, where('pid', '==', pid));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length > 0) {
            const now = [];
            querySnapshot.forEach(doc => {
              now.push({ id: doc.id, ...doc.data() });
            });
            setData(now.sort((a, b) => b.time - a.time));
          } else {
            setData([]);
          }
          setLoading(false);
        }
      })();
    }
  }, [pid, purchaseRef]);

  return (
    <div className="flex">
      <div style={{ padding: 8, width: '100%' }}>
        <Space.Compact size="large">
          <Row gutter={8}>
            <Col span={16}>
              <Input
                value={search}
                onKeyDown={e => e.key === 'Enter' && handleSearch()}
                onChange={e => setSearch(e.target.value)}
                placeholder="PID"
              />
            </Col>
            <Col span={4}>
              <Button size={'large'} loading={loading} onClick={handleSearch}>
                <FileSearchOutlined />
              </Button>
            </Col>
          </Row>
        </Space.Compact>
        {data.length > 0 && (
          <Card style={{ margin: '20px 0' }}>
            <p>產編: {data[data.length - 1].pid}</p>
            <p>品名: {data[data.length - 1].name}</p>
            <p>規格: {data[data.length - 1].spec}</p>
            <p>單位: {data[data.length - 1].unit}</p>
          </Card>
        )}
        <div style={{ maxWidth: '100%', overflow: 'scroll' }}>
          <div>
            <TablePage loading={loading} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
