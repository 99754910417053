import React from 'react';
import { Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import format_with_substring from '../../utils/number';

export default function TTabel(props) {
  const { data } = props;
  if (!data || data.length === 0) {
    return null;
  }
  const columns = [
    {
      title: '日期',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      render: (_, d) => `${d.year}/${d.month}/${d.day}`
    },
    {
      title: '產品編號',
      dataIndex: 'pid',
      key: 'pid',
      align: 'center'
    },
    {
      title: '品名',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: '規格',
      dataIndex: 'spec',
      key: 'spec',
      align: 'center'
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right'
    },
    {
      title: '單位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center'
    },
    {
      title: '單價',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: t => format_with_substring(t)
    },
    {
      title: '總價',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (_, row) => format_with_substring((row.price * row.quantity).toFixed(2))
    },
    {
      title: '退貨',
      dataIndex: 'return',
      key: 'return',
      align: 'center',
      width: 60,
      render: t => t && <CheckOutlined />
    }
  ];

  return (
    <>
      <p style={{ margin: '4px 0' }}>
        <span style={{ marginRight: 10 }}>廠商：{data[0].vendor}</span>
      </p>
      <Table
        loading={props.loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="id"
        style={{ marginTop: 4 }}
        summary={pageData => {
          let totalPrice = 0;
          let somePrice = 0;
          pageData.forEach(({ quantity, price, return: R, pid }) => {
            if (R) {
              totalPrice -= quantity * price;
            } else {
              totalPrice += quantity * price;
            }
            if (/^R500\d/.test(pid)) {
              if (R) {
                somePrice -= quantity * price;
              } else {
                somePrice += quantity * price;
              }
            }
          });
          return (
            <>
              {somePrice && (
                <Table.Summary.Row>
                  <Table.Summary.Cell>小計</Table.Summary.Cell>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <Table.Summary.Cell key={index}></Table.Summary.Cell>
                    ))}
                  <Table.Summary.Cell>
                    <div style={{ textAlign: 'right' }}>
                      {somePrice ? format_with_substring(somePrice.toFixed(2)) : null}
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div style={{ textAlign: 'right' }}>
                      {somePrice
                        ? format_with_substring((totalPrice - somePrice).toFixed(2))
                        : format_with_substring(totalPrice.toFixed(2))}
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              <Table.Summary.Row>
                <Table.Summary.Cell>總計</Table.Summary.Cell>
                {Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <Table.Summary.Cell key={index}></Table.Summary.Cell>
                  ))}
                <Table.Summary.Cell>
                  <div style={{ textAlign: 'right' }}>
                    {format_with_substring(totalPrice.toFixed(2))}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}
