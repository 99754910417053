import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext({
  user: null,
  setUser: null,
  signOut: null
});

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const getFromStorage = () => {
    const user = auth.currentUser;
    // console.log('🚀 ~ file: auth.jsx:14 ~ getFromStorage ~ user:', user);
    // console.log('🚀 ~ file: auth.jsx:14 ~ getFromStorage ~ user:', user);
    const userFromStorage = sessionStorage.getItem('user');
    if (userFromStorage) {
      return JSON.parse(userFromStorage);
    }
    return user;
  };

  const [user, setUser] = useState(getFromStorage());
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, user => setUser(user));
    return () => unregisterAuthObserver();
  }, [auth]);

  const setToStorage = user => {
    // console.log('🚀 ~ file: auth.jsx:21 ~ setToStorage ~ user:', user);
    setUser(user);
    sessionStorage.setItem('user', JSON.stringify(user));
  };

  const removeFromStorage = () => {
    sessionStorage.removeItem('user');
    setUser(null);
  };

  const value = {
    user,
    setUser: setToStorage,
    signOut: removeFromStorage
  };

  // onAuthStateChanged(auth, user => {
  //   // console.log('🚀 ~ file: auth.jsx:55 ~ AuthProvider ~ user:', user);
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/firebase.User
  //     // const uid = user.uid;
  //     // ...
  //   } else {
  //     // User is signed out
  //     // ...
  //   }
  // });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
