import React, { useState, useRef } from 'react';
import base64 from 'base-64';
import utf8 from 'utf8';
import { Input, Row, Col, Button, Space } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { collection, query, where, getDocs, writeBatch, getDoc } from 'firebase/firestore';
import db from '../../utils/firestore';

export default function Index() {
  const { current: purchaseRef } = useRef(collection(db, 'purchase'));
  const { current: productRef } = useRef(collection(db, 'product'));
  const [data, setData] = useState({ origin: '', revise: '', pid: '', name: '' });
  const [loading, setLoading] = useState(false);

  const handleChangeId = async () => {
    if (data.origin !== '' && data.revise !== '') {
      setLoading(true);
      const batch = writeBatch(db);
      // const batch = firestore.batch();
      const querySnapshot = await getDocs(query(purchaseRef, where('pid', '==', data.origin)));
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach(doc => {
          const nowDoc = doc(purchaseRef, doc.id);
          batch.update(nowDoc, { pid: data.revise });
        });
      }
      // 確認是否重複
      const productNewData = await getDocs(query(productRef, where('pid', '==', data.revise)));
      const productOldData = await getDocs(query(productRef, where('pid', '==', data.origin)));
      productOldData.forEach(async doc => {
        const nowDoc = await getDoc(doc(productRef, doc.id));
        if (productNewData.docs.length === 0) {
          const nowData = doc.data();
          nowData.pid = data.revise;
          const bytes = utf8.encode(nowData.vendor);
          const encoded = base64.encode(bytes);
          const pdoc = await getDoc(doc(productRef, `${encoded}${nowData.pid}`));
          batch.set(pdoc, nowData);
        }
        batch.delete(nowDoc);
      });
      await batch.commit();
      setLoading(false);
    }
  };

  const handleSearchName = async () => {
    if (data.pid !== '') {
      setLoading(true);
      const purchaseData = await getDocs(query(productRef, where('pid', '==', data.pid)));
      if (purchaseData.docs.length > 0) {
        purchaseData.forEach(doc => {
          const nowData = doc.data();
          setData({ ...data, name: nowData.name });
        });
      }
      setLoading(false);
    }
  };

  const handleChangeName = async () => {
    if (data.pid !== '' && data.name !== '') {
      setLoading(true);
      const batch = writeBatch(db);
      const purchaseData = await getDocs(query(purchaseRef, where('pid', '==', data.pid)));
      if (purchaseData.docs.length > 0) {
        purchaseData.forEach(async doc => {
          const nowDoc = await getDoc(doc(purchaseRef, doc.id));
          batch.update(nowDoc, { name: data.name });
        });
      }
      const productData = await getDocs(query(productRef, where('pid', '==', data.pid)));
      if (productData.docs.length > 0) {
        productData.forEach(async doc => {
          const nowDoc = await getDoc(doc(productRef, doc.id));
          batch.update(nowDoc, { name: data.name });
        });
      }
      await batch.commit();
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <div style={{ padding: 8, width: '100%' }}>
        <div style={{ margin: '10px 0' }}>
          <Space.Compact size="large">
            <Row gutter={8}>
              <Col span={5}>
                <Input
                  value={data.origin}
                  onChange={e => setData({ ...data, origin: e.target.value })}
                  placeholder="原本的 PID"
                />
              </Col>
              <Col span={1} style={{ textAlign: 'center' }}>
                <CaretRightOutlined style={{ fontSize: 20, padding: '10px 0' }} />
              </Col>
              <Col span={5}>
                <Input
                  value={data.revise}
                  onChange={e => setData({ ...data, revise: e.target.value })}
                  placeholder="改變的 PID"
                />
              </Col>
              <Col span={4}>
                <Button size={'large'} loading={loading} onClick={handleChangeId}>
                  改 ID
                </Button>
              </Col>
            </Row>
          </Space.Compact>
        </div>
        <div style={{ margin: '10px 0' }}>
          <Space.Compact size="large">
            <Row gutter={8}>
              <Col span={5}>
                <Input
                  value={data.pid}
                  onChange={e => setData({ ...data, pid: e.target.value })}
                  onKeyDown={e => e.key === 'Enter' && handleSearchName()}
                  placeholder="需要改的 PID"
                />
              </Col>
              <Col span={8}>
                <Input
                  value={data.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                  placeholder="新的名稱"
                />
              </Col>
              <Col span={4}>
                <Button size={'large'} loading={loading} onClick={handleChangeName}>
                  改名稱
                </Button>
              </Col>
            </Row>
          </Space.Compact>
        </div>
      </div>
    </div>
  );
}
