import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Modal, Form, Select } from 'antd';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Query(props) {
  const [form] = Form.useForm();
  useEffect(() => {
    const data = { ...props.data };
    if (props.data.isDetail !== props.isDetail) {
      data.vendor = props.isDetail ? props.VOption[0] : props.VOption;
    }
    form.setFieldsValue(data);
  }, [props.visible, form, props.data, props.isDetail, props.VOption]);
  const handleOk = async e => {
    const a = await form.validateFields();
    props.onSave({ ...props.data, ...a, type: 'DATE', isDetail: props.isDetail });
    props.onClose();
  };

  const handleCancel = e => {
    props.onClose();
  };

  return (
    <Modal
      title="查詢"
      open={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔"
      cancelText="取消"
      maskClosable={false}
    >
      <Form className="edit" form={form} scrollToFirstError>
        <Form.Item
          name="date"
          label="月份"
          rules={[{ required: true, message: 'Missing day' }]}
          initialValue={[
            dayjs().subtract(1, 'months').startOf('month'),
            dayjs().subtract(1, 'months').endOf('month')
          ]}
        >
          <RangePicker allowClear={false} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="vendor"
          label="廠商"
          rules={[{ required: true, message: 'Missing vendor' }]}
          initialValue={props.isDetail ? props.VOption[0] : props.VOption}
        >
          <Select
            style={{ width: '100%' }}
            allowClear
            mode={props.isDetail ? undefined : 'multiple'}
          >
            {props.VOption.map(i => (
              <Option key={i} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
