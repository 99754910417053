import React from 'react';
import { Table } from 'antd';
import { EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import format_with_substring from '../../utils/number';

export default function ListTable(props) {
  const { data, onEdit, onDel } = props;
  const columns = [
    {
      title: '產品編號',
      dataIndex: 'pid',
      key: 'pid',
      width: 110,
      align: 'center'
    },
    {
      title: '品名',
      dataIndex: 'name',
      key: 'name',
      align: 'left'
    },
    {
      title: '規格',
      dataIndex: 'spec',
      key: 'spec',
      width: 100,
      align: 'center'
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 60,
      align: 'right'
    },
    {
      title: '單位',
      dataIndex: 'unit',
      key: 'unit',
      width: 60,
      align: 'center'
    },
    {
      title: '單價',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 70,
      render: t => format_with_substring(t)
    },
    {
      title: '總價',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 110,
      render: (_, row) => format_with_substring((row.price * row.quantity).toFixed(2))
    },
    {
      title: '退貨',
      dataIndex: 'return',
      key: 'return',
      align: 'center',
      width: 60,
      render: t => t && <CheckOutlined />
    },
    {
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: 60,
      render: (_, row) => (
        <>
          <EditOutlined onClick={onEdit.bind(null, row)} style={{ marginRight: 4 }} />
          <DeleteOutlined onClick={onDel.bind(null, row)} />
        </>
      )
    }
  ];

  return (
    <>
      <p style={{ margin: '4px 0' }}>
        <span style={{ marginRight: 10 }}>廠商：{data[0].vendor}</span>
        <span>
          日期：{data[0].year}/{data[0].month}/{data[0].day}
        </span>
      </p>
      <Table
        loading={props.loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="id"
        scroll={{ x: 900 }}
        style={{ marginTop: 4 }}
        summary={pageData => {
          let totalPrice = 0;
          pageData.forEach(({ quantity, price, return: R }) => {
            if (R) {
              totalPrice -= quantity * price;
            } else {
              totalPrice += quantity * price;
            }
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>總計</Table.Summary.Cell>
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <Table.Summary.Cell key={index}></Table.Summary.Cell>
                  ))}
                <Table.Summary.Cell>
                  <div style={{ textAlign: 'right' }}>
                    {format_with_substring(totalPrice.toFixed(2))}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
}

/**

 */
