import React from 'react';
import { Table } from 'antd';
import format_with_substring from '../../utils/number';

export default function TableTotal(props) {
  const { data } = props;
  if (!data || data.length === 0) {
    return null;
  }
  const total = {};
  const someTotal = {};
  const IsSpecial = data[0].vendor === '源吉';

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    // console.log(item)
    const nowTotal = item.price * item.quantity;
    if (IsSpecial && /^R500\d/.test(item.pid)) {
      if (!someTotal[`${item.year}/${item.month}/${item.day}`]) {
        someTotal[`${item.year}/${item.month}/${item.day}`] = 0;
      }
      if (item.return) {
        someTotal[`${item.year}/${item.month}/${item.day}`] -= nowTotal;
      } else {
        someTotal[`${item.year}/${item.month}/${item.day}`] += nowTotal;
      }
    } else {
      if (!total[`${item.year}/${item.month}/${item.day}`]) {
        total[`${item.year}/${item.month}/${item.day}`] = 0;
      }
      if (item.return) {
        total[`${item.year}/${item.month}/${item.day}`] -= nowTotal;
      } else {
        total[`${item.year}/${item.month}/${item.day}`] += nowTotal;
      }
    }
  }
  // console.log(total);
  const nowData = Object.keys(total)
    .map(i => ({ date: i, total: total[i], someTotal: someTotal[i] }))
    .sort((a, b) => parseInt(a.date.replace('/', '')) - parseInt(b.date.replace('/', '')));
  // console.log(nowData)
  const columns = [
    {
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      align: 'center'
    },
    {
      title: '總價',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: d => (d ? format_with_substring(d.toFixed(2)) : null)
    }
  ];

  if (IsSpecial) {
    columns.splice(1, 0, {
      title: '金紙',
      dataIndex: 'someTotal',
      key: 'someTotal',
      align: 'right',
      render: d => (d ? format_with_substring(d.toFixed(2)) : null)
    });
  }

  return (
    <>
      <p style={{ margin: '4px 0' }}>
        <span style={{ marginRight: 10 }}>廠商：{data[0].vendor}</span>
      </p>
      <Table
        dataSource={nowData}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="date"
        style={{ marginTop: 4 }}
        summary={pageData => {
          let totalPrice = 0;
          let somePrice = 0;
          pageData.forEach(({ total, someTotal }) => {
            totalPrice += total;
            if (someTotal) somePrice += someTotal;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell></Table.Summary.Cell>
                {IsSpecial && <Table.Summary.Cell></Table.Summary.Cell>}
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell>總計</Table.Summary.Cell>
                {/* {Array(5).fill(null).map((_, index) => (
                                    <Table.Summary.Cell key={index}></Table.Summary.Cell>
                                ))} */}
                {IsSpecial && (
                  <Table.Summary.Cell>
                    <div style={{ textAlign: 'right' }}>
                      {somePrice ? format_with_substring(somePrice.toFixed(2)) : null}
                    </div>
                  </Table.Summary.Cell>
                )}
                <Table.Summary.Cell>
                  <div style={{ textAlign: 'right' }}>
                    {format_with_substring(totalPrice.toFixed(2))}
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              {IsSpecial && (
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div style={{ textAlign: 'right' }}>
                      {format_with_substring((somePrice + totalPrice).toFixed(2))}
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
}
