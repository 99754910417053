import React from 'react';
import { Table } from 'antd';
import format_with_substring from '../../utils/number';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const { data } = props;
  if (!data || data.length === 0) {
    return null;
  }
  const columns = [
    {
      title: '日期',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      render: (_, d) => `${d.year}/${d.month}/${d.day}`
    },
    {
      title: '廠商',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center'
    },
    // {
    //   title: '產品編號',
    //   dataIndex: 'pid',
    //   key: 'pid',
    //   align: 'center'
    // },
    {
      title: '單價',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: t => format_with_substring(t.toFixed(2))
    }
    // {
    //   title: '品名',
    //   dataIndex: 'name',
    //   key: 'name',
    //   align: 'left'
    // },
    // {
    //   title: '規格',
    //   dataIndex: 'spec',
    //   key: 'spec',
    //   align: 'center'
    // },
    // {
    //   title: '單位',
    //   dataIndex: 'unit',
    //   key: 'unit',
    //   align: 'center'
    // }
  ];

  return (
    <Table
      loading={props.loading}
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="id"
      style={{ marginTop: 4 }}
    />
  );
}
